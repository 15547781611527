import React, { CSSProperties, FunctionComponent } from 'react';
import { MuiIconManifest } from 'utils/iconManifest';

interface VerifiedBadgeProps {
  fontSize: 'medium' | 'large';
  paddingBottom?: CSSProperties['padding'] | null;
}

export const VerifiedBadge: FunctionComponent<VerifiedBadgeProps> = ({
  fontSize,
  paddingBottom,
}) => (
  <MuiIconManifest.CheckCircleIcon
    style={{
      color: '#18A2F2',
      paddingBottom:
        paddingBottom === undefined
          ? '0.2em' // todo see if I can get rid of this hack
          : paddingBottom === null
          ? undefined
          : paddingBottom,
    }}
    fontSize={fontSize}
  />
);
