import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useState, useEffect, useRef, ReactNode } from 'react';

type TooltipTypographyProps = {
  children: ReactNode; // the children to render inside the Typography
  textForLimit: string; // the text to use to check if it exceeds the character limit
  charLimit: number; // the character limit before showing the tooltip
  slotProps?: {
    tooltip?: TooltipProps;
    typography?: TypographyProps;
  };
};

/**
 * A reusable TooltipTypography component that displays a tooltip when the text exceeds a character limit or overflows.
 *
 * @param {Object} param0 - The props object for TooltipTypography.
 * @param {string} param0.textForLimit - The text to display in the Typography.
 * @param {number} param0.charLimit - The maximum character limit before showing the tooltip.
 * @param {TooltipProps} [param0.slotProps.tooltip] - Props to pass to the Tooltip component.
 * @param {TypographyProps} [param0.slotProps.typography] - Props to pass to the Typography component.
 * @returns {JSX.Element} - The TooltipTypography component.
 */
export const TooltipTypography = ({
  children,
  textForLimit,
  charLimit,
  slotProps,
}: TooltipTypographyProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const { scrollWidth, clientWidth } = textRef.current;
        setIsOverflowing(
          scrollWidth > clientWidth || textForLimit.length > charLimit
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [textForLimit, charLimit]);

  return (
    <Tooltip
      title={isOverflowing ? textForLimit : ''}
      disableHoverListener={!isOverflowing}
      {...slotProps?.tooltip}
    >
      <Typography
        ref={textRef}
        noWrap
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        {...slotProps?.typography}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
